.about-us-page-container {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 10px 20px;
	gap: 20px;

	h2 {
		font-size: 16px;
	}

	.president-container {
		margin-top: 45px;

		h3 {
			font-size: 13px;
		}

		.president-description {
			font-size: 13px;
		}
	}

	.provided-services {
		display: grid;
		grid-template-columns: 160px auto;
		padding-left: 8px;

		li {
			font-size: 13px;
			list-style-type: none;
			padding: 1px;
		}

	}

	.small-business-specialist-image {
		position: absolute;
		right: 20px;
		top: 12px;
	}

	.ms-certified-image {
		position: absolute;
		right: 0;
		bottom: -70px;
		width: 150px;
	}

}