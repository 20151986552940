#services-offered-container {

	display: grid;
	margin: 10px 20px;

	h2 {
		font-size: 16px;
	}

	p {
		font-size: 16px;
	}

	.services-columns {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 100px;

		.ms-office-offer {
			display: flex;
			flex-direction: column;

			a {
				width: 180px;
				padding: 7px 0;

				img {
					width: 100%;
				}
			}
		}
	}
}