.home-page-container {
	display: grid;
	grid-template-columns: 720px auto;
	gap: 40px;
    margin: 10px 20px;

	.descriptions-container {
		h2 {
			font-size: 16px;
		}

		p {
			font-size: 16px;
		}
	}

	.image-gallery {
		display: flex;
		flex-direction: column;
		gap: 20px;

		img {
			max-width: 100%;
		}
	}
}