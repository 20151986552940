.contact-us-completed-container {

	display: none;
	visibility: hidden;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	align-items: center;
	justify-content: center;
	background-color: white;

	p {
		font-size: 30px;
	}

	&.visible {
		display: flex;
		visibility: visible;
	}

}