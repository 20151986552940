#footer {
	width: 100%;
	border-bottom: 10px solid #333333;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		font-size: 13px;
		margin: 0;
	}

}