.navigation-contianer {

	.navigation-container__images {
		display: flex;
		position: relative;
	}

	.award-logo {
		position: absolute;
		width: 93px;
		right: 410px;
	}

	.navigation {
		background-color: #ff0000;

		display: flex;
		gap: 5px;
		margin: 0;
		padding: 0;
		font-size: 12px;

		.navigation-options {
			display: flex;
			gap: 1px;
			background-color: white;

			.option {
				margin: 0;
				padding: 5px 11px;
				color: white;
				font-size: 12px;
				background-color: #ff0000;
				cursor: pointer;
				user-select: none;

				&.selected {
					background-color: #660000;
				}
			}
		}
	}

}