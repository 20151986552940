#contact-form-container {

	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 10px 20px;

	h2 {
		font-size: 16px;
	}

	p {
		font-size: 16px;
	}
	
	.contact-form-text {
		padding-right: 20px;
	}

	.form-control-container {
		display: flex;
	/*	padding: 40px 210px; */
		padding-top: 20px; padding-bottom: 20px;
		position: relative;
	
		.form-control {
			display: flex;
			width: 100%;
			flex-direction: column;
			gap: 20px;
		}
	}
}